import React from "react"
import { Link, graphql } from "gatsby"
import { Container } from 'reactstrap'
import Img from "gatsby-image"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Index({ data }) {
  const { coverImage, coffeeImage } = data
  // console.log('index.js data', data)

  return (
    <Layout headerImage={coverImage.childImageSharp.fluid}>
      <SEO title="Main page" keywords={[`Tangerine Fix`, `coffee`]} />
      <Container>
        <div className="col">
          <Link
            to={`/products-coffee`}
            className="card-link"
            css={css`
              text-decoration: none;
              color: inherit;
              &:hover {
                text-decoration: none;
                color: inherit;
              }
            `}
          >
            <div className="card bg-dark text-white m-1" css={css`
              border: 1em;
              width: 15rem;
              height: 8rem;
            `}>
              {coffeeImage && 
                <Img class="card-img" css={css`
                  opacity: 0.3;
                `}
                  fluid={coffeeImage.childImageSharp.fluid}/>
              }
              <div className="card-img-overlay">
                <h5 className="card-title">Coffee</h5>
                <h6 className="card-subtitle">Micro lot, fresh roasted in micro batches</h6>
              </div>
            </div>
          </Link>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProductPageQuery {
    coverImage: file(
      relativePath: { regex: "/IMG_2350c/" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 1024
          quality: 80
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coffeeImage: file(
      relativePath: { regex: "/IMG_0890.jpg/" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 412
          quality: 80
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
 `